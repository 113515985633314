import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { EStatus } from '@common/definitions';
import { EPulseSurveyStatus } from '@modules/survey/types/objects';
import { ESurveyStatus } from '@modules/survey/definitions';
import { Icon, IconTypes } from '../icon';

import './styles.scss';

export type BadgeProps = PropsWithChildren<{
  status?: EStatus | EPulseSurveyStatus | ESurveyStatus;
  icon?: IconTypes;
  text?: string;
}>;

// Can be expanded to show other types of badges besides status
const Badge = ({ status, icon, text, children }: BadgeProps) => {
  const { t } = useTranslation();
  if (status) {
    return (
      <div className={`Badge BadgeStatus BadgeStatus--${status}`}>
        {t('common:status', { context: status })}
      </div>
    );
  }
  return (
    <div className="Badge">
      {icon && <Icon className="Badge__Icon" type={icon} />}
      {text || children}
    </div>
  );
};

export default Badge;
